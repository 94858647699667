<template>
  <auth-template title="Reset Password">
    <form v-on:submit.prevent="resetPassword()">
      <div class="field">
        <div class="control">
          <b-input type="email" placeholder="Your Email"
                   v-model="email"></b-input>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <b-input type="password" placeholder="Your New Password"
                   v-model="password"></b-input>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <b-input type="password" placeholder="Your Password Confirmation"
                   v-model="password_confirmation"></b-input>
        </div>
      </div>
      <button type="submit" class="button is-block is-info is-fullwidth">
        Reset Password
      </button>
    </form>
  </auth-template>
</template>

<script>
import AuthTemplate from "@/components/auth/AuthTemplate";

export default {
  name: "PasswordReset",
  components: {AuthTemplate},
  data() {
    return {
      token: this.$route.params.token,
      email: this.$route.query.email,
      password: null,
      password_confirmation: null,
      has_error: false
    }
  },
  methods: {
    resetPassword() {
      this.$http.post(process.env.VUE_APP_HOST + "/password/reset/" + this.token, {
            email: this.email,
            token: this.token,
            password: this.password,
            password_confirmation: this.password_confirmation
          })
          .then(() => {
            // console.log(result.data);
            this.$router.push({name: 'login'})
          }, error => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
    }
  }
}
</script>

<style scoped>

</style>